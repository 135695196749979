<template>
    <div>
        <layout-header title="Users">
            <layout-button href="/users/create" v-if="can_create">Create New User</layout-button>
        </layout-header>

        <table-table>
            <template v-slot:header>
                <table-row type="header">
                    <table-header @click="updateSort('id')" hide-sm>ID</table-header>
                    <table-header @click="updateSort('last_name')">Name</table-header>
                    <table-header @click="updateSort('email')" hide-sm>Email</table-header>
                    <table-header @click="updateSort('client_name')" hide-sm>Client Name</table-header>
                    <table-header @click="updateSort('account_level')" hide-sm>Level</table-header>
                    <table-header @click="updateSort('status')" hide-sm>Status</table-header>
                    <table-header type="text" last>Actions</table-header>
                </table-row>
            </template>
            <template v-slot:body>
                <table-row v-for="(user, index) in users" :key="user.id" :index="index">
                    <table-cell hide-sm bold>{{ user.id }}</table-cell>
                    <table-cell>{{ user.first_name }} {{ user.last_name }}</table-cell>
                    <table-cell hide-sm>{{ user.email }}</table-cell>
                    <table-cell hide-sm>{{ user.client_name }}</table-cell>
                    <table-cell hide-sm>{{ this.$filters.capitalize(user.account_level) }}</table-cell>
                    <table-cell hide-sm><table-status-badge :status="user.status"></table-status-badge></table-cell>
                    <table-cell last>
                        <a href="javascript:void(0);" v-on:click="impersonateUser(user.id)" v-if="can_update" class="mx-2"><i class="far fa-eye"></i></a>
                        <table-edit-link :href="{ name: 'edit_user', params: { id: user.id }}" v-if="can_update"></table-edit-link>
                        <table-delete-link @click="confirmDelete(user.id)" v-if="can_update"></table-delete-link>
                    </table-cell>
                </table-row>
            </template>
        </table-table>

        <paginate
            :page-count="page_count"
            :click-handler="fetch"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination flex justify-center gap-2'"
            :page-class="'pagination-page'"
            :prev-class="'pagination-prev'"
            :next-class="'pagination-next'"
            :disabled-class="'pagination-inactive'">
        </paginate>

        <ConfirmModal
            name="delete-confirm"
            height="220"
            title="Delete User?"
            description="Are you sure you want to delete this user?  This action cannot be undone."
            actionText="Delete"
            :show="open_delete_modal"
            @confirm="deleteClient()"
            @cancel="open_delete_modal = false"
        />
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App';
    import LayoutHeader from '../../shared/layout/Header';
    import LayoutButton from '../../shared/layout/Button';
    import TableTable from '../../shared/table/Table';
    import TableRow from '../../shared/table/Row';
    import TableHeader from '../../shared/table/Header';
    import TableCell from '../../shared/table/Cell';
    import TableStatusBadge from '../../shared/table/StatusBadge';
    import TableEditLink from '../../shared/table/EditLink';
    import TableDeleteLink from '../../shared/table/DeleteLink';
    import ConfirmModal from '../../shared/modal/ConfirmModal';

    export default {
        name: 'ListUsers',
        components: {
            LayoutApp,
            LayoutHeader,
            LayoutButton,
            TableTable,
            TableRow,
            TableHeader,
            TableCell,
            TableStatusBadge,
            TableEditLink,
            TableDeleteLink,
            ConfirmModal,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));

            this.fetch();
            this.can_create = (this.current_user.account_level === 'owner' || this.current_user.account_level === 'admin');
            this.can_update = (this.current_user.account_level === 'owner' || this.current_user.account_level === 'admin');
        },
        computed: {
            current_user: function() {
                return this.$store.getters.user;
            }
        },
        data() {
            return {
                users: [],
                delete_id: null,
                page_count: 1,
                sort: 'desc',
                order: 'id',
                can_create: false,
                can_update: false,
                open_delete_modal: false,
            }
        },
        methods: {
            fetch(page) {
                this.$http.get(this.url(page)).then(this.refresh);
            },
            url(page) {
                if (!page) {
                    page = 1;
                }
                return `/api/v1/admin/users?page=${page}&sort=${this.sort}&order=${this.order}`;
            },
            refresh({data}) {
                this.users = data.data;
                this.page_count = data.meta.pages;
                window.scrollTo(0,0);
            },
            impersonateUser(id) {
                this.$store.dispatch('impersonate', { id })
                    .then(() => {
                        // Should use router and reload layout data... not sure how...
                        //this.$router.push('/')
                        //this.$emit('update:layout', LayoutApp);
                        window.location = '/';
                    })
                    .catch(err => {

                    })
            },
            confirmDelete(id) {
                this.delete_id = id;
                this.open_delete_modal = true;
            },
            deleteUser() {
                this.$http.delete(`/api/v1/admin/users/${this.delete_id}`).then(this.postDelete);
            },
            postDelete() {
                this.open_delete_modal = false;
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    text: 'The user has been deleted',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch(1);
            },
            updateSort(field) {
                if (field === this.order) {
                    this.sort = (this.sort === 'asc' ? 'desc' : 'asc');
                } else {
                    this.sort = 'asc';
                }

                this.order = field;

                this.fetch(1);
            },
        }
    }
</script>
