<template>
    <div>
        <layout-header title="API Tokens">
            <layout-button href="/api_tokens/create" v-if="user.is_admin">Create New API Token</layout-button>
        </layout-header>

        <table-table>
            <template v-slot:header>
                <table-row type="header">
                    <table-header @click="updateSort('id')" hide-sm>ID</table-header>
                    <table-header @click="updateSort('name')">Name</table-header>
                    <table-header @click="updateSort('user_id')" hide-sm>User ID</table-header>
                    <table-header @click="updateSort('key')" hide-sm>Key</table-header>
                    <table-header @click="updateSort('token')" hide-sm>API Token</table-header>
                    <table-header @click="updateSort('status')" hide-sm>Status</table-header>
                    <table-header type="text" last>Actions</table-header>
                </table-row>
            </template>
            <template v-slot:body>
                <table-row v-for="(api_token, index) in api_tokens" :key="api_token.id" :index="index">
                    <table-cell hide-sm bold>{{ api_token.id }}</table-cell>
                    <table-cell>{{ api_token.name }}</table-cell>
                    <table-cell hide-sm>{{ api_token.user_id }}</table-cell>
                    <table-cell hide-sm>{{ api_token.key }}</table-cell>
                    <table-cell hide-sm>
                        <span>*************</span>
                        <i class="fas fa-copy px-3 cursor-pointer" @click="onTokenCopy($event, api_token.token)"></i>
                    </table-cell>
                    <table-cell hide-sm><table-status-badge :status="api_token.status"></table-status-badge></table-cell>
                    <table-cell last>
                        <table-edit-link :href="{ name: 'edit_api_token', params: { id: api_token.id }}"></table-edit-link>
                        <table-delete-link @click="confirmDelete(api_token.id)"></table-delete-link>
                    </table-cell>
                </table-row>
            </template>
        </table-table>

        <paginate
            :page-count="page_count"
            :click-handler="fetch"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination flex justify-center gap-2'"
            :page-class="'pagination-page'"
            :prev-class="'pagination-prev'"
            :next-class="'pagination-next'"
            :disabled-class="'pagination-inactive'">
        </paginate>

        <ConfirmModal
            name="delete-confirm"
            height="220"
            title="Delete API Token?"
            description="Are you sure you want to delete this API Token?  This action cannot be undone."
            actionText="Delete"
            :show="open_delete_modal"
            @confirm="deleteClient()"
            @cancel="open_delete_modal = false"
        />
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App';
    import LayoutHeader from '../../shared/layout/Header';
    import LayoutButton from '../../shared/layout/Button';
    import TableTable from '../../shared/table/Table';
    import TableRow from '../../shared/table/Row';
    import TableHeader from '../../shared/table/Header';
    import TableCell from '../../shared/table/Cell';
    import TableStatusBadge from '../../shared/table/StatusBadge';
    import TableEditLink from '../../shared/table/EditLink';
    import TableDeleteLink from '../../shared/table/DeleteLink';
    import ConfirmModal from '../../shared/modal/ConfirmModal';

    export default {
        name: 'ListAPITokens',
        components: {
            LayoutApp,
            LayoutHeader,
            LayoutButton,
            TableTable,
            TableRow,
            TableHeader,
            TableCell,
            TableStatusBadge,
            TableEditLink,
            TableDeleteLink,
            ConfirmModal,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));

            this.fetch();
            this.fetchUser();
        },
        data() {
            return {
                api_tokens: [],
                delete_id: null,
                page_count: 1,
                sort: 'desc',
                order: 'id',
                user: null,
                open_delete_modal: false,
            }
        },
        methods: {
            fetch(page) {
                this.$http.get(this.url(page)).then(this.refresh);
            },
            url(page) {
                if (!page) {
                    page = 1;
                }
                return `/api/v1/admin/api_tokens?page=${page}&sort=${this.sort}&order=${this.order}`;
            },
            refresh({data}) {
                this.api_tokens = data.data;
                this.page_count = data.meta.pages;
                window.scrollTo(0,0);
            },
            fetchUser() {
                this.user = this.$store.getters.user;
            },
            confirmDelete(id) {
                this.delete_id = id;
                this.open_delete_modal = true;
            },
            deleteAPIToken() {
                this.$http.delete(`/api/v1/admin/api_tokens/${this.delete_id}`).then(this.postDelete);
            },
            postDelete() {
                this.open_delete_modal = false;
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    text: 'The API Token has been deleted',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch(1);
            },
            updateSort(field) {
                if (field === this.order) {
                    this.sort = (this.sort === 'asc' ? 'desc' : 'asc');
                } else {
                    this.sort = 'asc';
                }

                this.order = field;

                this.fetch(1);
            },
            onTokenCopy(e, token) {
                navigator.clipboard.writeText(text);

                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    text: 'API token successfully copied',
                    duration: 5000,
                    speed: 1000
                });
            },
        }
    }
</script>
